import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Styles.css';
import Cookies from 'js-cookie';

const Labform = ({ selectedTest, onSelectTest }) => {
    const userid = Cookies.get('userid');
    const portalid = Cookies.get('portalid');
    const username = Cookies.get('name');
    const [parameters, setParameters] = useState([]);
    const [headerTitle, setHeaderTitle] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [resultHistory, setResultHistory] = useState([]);
    const [values, setValues] = useState({
        age: '',
        gender: '',
        height: '',
        weight: '',
    });
    const [submissionResult, setSubmissionResult] = useState('');
    const [procedureCategory, setProcedureCategory] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    console.log('userid', userid);
    console.log('name', username);
    console.log('portalid', portalid);
    console.log('selectedTest', selectedTest);

    useEffect(() => {
        axios.get(`/api/parameters/${selectedTest}`)
            .then(response => {
                console.log(response.data);
                if (response.data && response.data.length > 0) {
                    setProcedureCategory(response.data[0].procedure_category);
                    setParameters(response.data);
                }
            })
            .catch(error => {
                setErrorMessage('Error fetching parameters: ' + error.message);
            });
        axios.get(`/api/header/${selectedTest}`)
            .then(response => {
                console.log(response.data);
                if (response.data && response.data.length > 0) {
                    setHeaderTitle(response.data[0].NAME);
                } else {
                    setHeaderTitle('Header Not Found');
                }
            })
            .catch(error => {
                setErrorMessage('Error fetching header: ' + error.message);
            });

        axios.get(`/api/result-history/${userid}`)
            .then(response => {
                console.log(response.data);
                setResultHistory(response.data);
            })
            .catch(error => {
                console.error('Error fetching result history:', error);
            });
    }, [selectedTest, userid]);

    const handleInputChange = (e, paramName) => {
        const updatedValues = { ...values };
        updatedValues[paramName] = e.target.value;
        setValues(updatedValues);
    };

    const handleSubmit = () => {
        setIsLoading(true); // Start loading

        const additionalFields = {
            age: values.age,
            gender: values.gender,
            height: values.height,
            weight: values.weight,
        };

        const allFormValues = { ...values, ...additionalFields };

        axios.post('/api/submit', { values: allFormValues, portalid: portalid, userid: userid, username: username, selectedTest })
            .then(response => {
                console.log('Received data from backend:', response.data);

                // Check if the received data is a valid JSON string
                try {
                    const parsedData = JSON.parse(response.data.received_data_from_python.output);
                    setSubmissionResult(parsedData);

                } catch (error) {
                    setSubmissionResult('Submission failed');
                }
            })
            .catch(error => {
                console.error('Error submitting values: ', error.message);
                setSubmissionResult('Submission failed');
            })
            .finally(() => {
                setIsLoading(false); // Stop loading
            });
    };

    console.log("submissionResult", submissionResult);

    const renderTable = () => {
        const additionalRows = [
            { name: 'age', units: 'years' },
            { name: 'gender', units: '' },
            { name: 'height', units: 'cm' },
            { name: 'weight', units: 'kg' },
        ];

        const allParameters = procedureCategory === 'Pindex'
            ? [...parameters, ...additionalRows]
            : parameters;

        return (<>
            {selectedTest === 'liver' && (<div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>Parameter Name</th>
                            <th>Units</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allParameters.map(param => (
                            <tr key={param.name}>
                                <td>{param.name}</td>
                                <td>{param.units}</td>
                                <td>
                                    <input
                                        type="text"
                                        value={values[param.name] || ''}
                                        onChange={e => handleInputChange(e, param.name)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>)}
        </>
        );
    };

    const renderSubmissionResult = () => {
        if (typeof submissionResult === 'object' && submissionResult !== null) {
            return (
                <div>
                    <h3>Submission Result:</h3>

                    {/* Table for model accuracies */}
                    <table>
                        <thead>
                            <tr>
                                <th>Model</th>
                                <th>Accuracy</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(submissionResult).filter(key => key.includes('Accuracy')).map((key, index) => (
                                <tr key={index}>
                                    <td>{key}</td>
                                    <td>{submissionResult[key]}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>




                    {/* Table for confusion matrix */}
                    <h4>Confusion Matrix</h4>
                    <table>
                        <tbody>
                            {submissionResult["Confusion Matrix"].map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                        <td key={cellIndex}>{cell}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {/* Display overall accuracy */}
                    <h4>Overall Accuracy</h4>
                    <table>
                        <tbody>
                            <tr>
                                <td>accuracy</td>
                                <td>{submissionResult["Classification Report"]["accuracy"]}</td>
                            </tr>
                        </tbody>
                    </table>

                    {/* Table for classification report */}
                    <h4>Classification Report</h4>
                    {/* {Object.keys(submissionResult["Classification Report"]).map((key, index) => ( */}
                    {Object.keys(submissionResult["Classification Report"]).filter(key => key !== 'accuracy').map((key, index) => (

                        <div key={index}>
                            <h5>Class {key}</h5>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Metric</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(submissionResult["Classification Report"][key]).map((metric, metricIndex) => (
                                        <tr key={metricIndex}>
                                            <td>{metric}</td>
                                            <td>{submissionResult["Classification Report"][key][metric]}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ))}
                </div>
            );
        }
        return <p>{submissionResult}</p>;
    };

    return (
        <div className="labform-and-history-container">
            {/* Labform container */}
            <div className="labform-container">
                {/* <div className="main-container">
          <h1>{headerTitle}</h1>
          {errorMessage && <div>{errorMessage}</div>}
        </div> */}
                {renderTable()}
                <button onClick={handleSubmit} disabled={isLoading}>
                    {isLoading ? 'Submitting...' : 'Submit'}
                </button>
                {isLoading && (
                    <div className="loader">
                        <img src="../images/website_images/Spinner-1s-200px.gif" alt="Loading..." style={{ width: '100px', height: '100px' }} />
                    </div>
                )}
                {submissionResult && (
                    <div className="submission-result">
                        {renderSubmissionResult()}
                    </div>
                )}
            </div>

            {/* Result history container */}
            {/* <div className="result-history-container">
        <p className='history-lab-p'>Click here to see your Result details</p>
        {resultHistory.length > 0 ? (
          <ul>
            {resultHistory.map((result, index) => (
              <li key={index}>{new Date(result.resultDateTime).toLocaleString()}</li>
            ))}
          </ul>
        ) : (
          <p>No result history available.</p>
        )}
      </div> */}
        </div>
    );
};

export default Labform;