import React, { useEffect, useState } from 'react';
import './LabTestScreen.css';
import LabForm from './Labform'; 
import Cookies from 'js-cookie';
import { useNavigate, useLocation } from 'react-router-dom';

const Lab = ({ onSelectTest }) => {
  const [showLabForm, setShowLabForm] = useState(false);
  const [selectedTest, setSelectedTest] = useState('');
  const userid = Cookies.get('userid');
  const navigate = useNavigate()


  useEffect(() => {
    if (!userid) {
      alert("Please login");
      navigate('/login');
    } 
  }, [userid, navigate]);

  const handleTestClick = (keyword) => {
    console.log("Selected test:", keyword);
    setSelectedTest(keyword); // Set the selected test
    setShowLabForm(true);
  };

  return (
    <div className="centered">
      <h1>Personal Index</h1>
      <div className="buttonContainer">
        {/* <div className="buttonWrapper">
          <button className="testButton liver" onClick={() => handleTestClick('liver')}>
            LFT (Liver Function Test)
          </button>
        </div>
        <div className="buttonWrapper">
          <button className="testButton kidney" onClick={() => handleTestClick('kidney')}>
            Kidney Function Test
          </button>
        </div>
        <div className="buttonWrapper">
          <button className="testButton heart" onClick={() => handleTestClick('heart')}>
            Heart Function Test
          </button>
        </div>
        <div className="buttonWrapper">
          <button className="testButton lungs" onClick={() => handleTestClick('lungs')}>
            Lungs Function Test
          </button>
        </div>
        <div className="buttonWrapper">
          <button className="testButton Air" onClick={() => handleTestClick('Air')}>
            Air Quality Test
          </button>
        </div> */}

        <div className="buttonWrapper">
          <button className="testButton Air" onClick={() => handleTestClick('diabetics')}>
          Diabetes
          </button>
        </div>
      </div>
      {showLabForm && (
        <LabForm selectedTest={selectedTest} onSelectTest={onSelectTest} />
      )}
    </div>
  );
};

export default Lab;
