import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import commentIcon from '../../../../components/public/comment.png'
import likesIcon from '../../../../components/public/like.png'

function InstaPosting(props) {

  const { bardStory, id, processedIDs } = props;
  const facebook_token = Cookies.get("facebook_token");
  const portalid = Cookies.get("portalid");
  const firmid = Cookies.get("firmid");
  const [image, setImage] = useState([]);
  const [data, setData] = useState([]);
  const userid = Cookies.get("userid")
  const [loading, setLoading] = useState(false);

  console.log("processedIDs in instaposting", processedIDs)

  useEffect(() => {
    setLoading(true);
    console.log("loading", loading)


    axios.post('/get/image/link', { "id": id })
      .then(function (response) {
        //console.log("image link in insta", response.data[0].image);
        const rawImage = response.data[0].image;
        let cleanedImage = rawImage.replace('..', ''); // Remove the leading two dots
        //cleanedImage = window.location.origin + cleanedImage
        cleanedImage = "https://www.myblocks.in" + cleanedImage
        console.log("image link in insta", cleanedImage);
        setImage(cleanedImage);

        //dev
        //const im ='https://picjumbo.com/wp-content/uploads/beautiful-nature-scenery-free-photo.jpg'
        //setImage(im);
      });

    fetchhistory();
    setLoading(false);
  }, []);


  const fetchhistory = async () => {
    try {
      const response = await axios.get('/post/history', {
        params: {
          id: id,
          social_name: 'instagram',
        },
      });
      console.log("insta history", response.data)

      setData(response.data);
    }

    catch (error) {
      console.log(error);
    }


  }

  const posttoinsta = async () => {
    setLoading(true);
    let postcount = 0;

    for (const insta_id of processedIDs) {
      try {
        console.log("insta_id.id", insta_id.id)
        //const response = await axios.post(`https://graph.facebook.com/v19.0/${insta_id}/media?image_url=http://61.2.142.91:7100/images/Myblock/News/f8b639_707efe994ed74f84b99bfb25976b7a8d~mv2.jpg&caption=${bardStory}&access_token=${facebook_token}`)
        const response = await axios.post(`https://graph.facebook.com/v19.0/${insta_id.id}/media?image_url=${image}&caption=${bardStory}&access_token=${facebook_token}`)
        console.log("insta 1st part", response.data)

        const responsenew = await axios.post(`https://graph.facebook.com/v19.0/${insta_id.id}/media_publish?creation_id=${response.data.id}&access_token=${facebook_token}`)
        console.log("insta 2st part", responsenew.data)

        const permalink = await axios.get(`https://graph.facebook.com/v19.0/${responsenew.data.id}?fields=permalink&access_token=${facebook_token}`)
        console.log("permalink", permalink.data)

        const postHistoryResponse = await axios.post('/instagram/post/history', { "url": permalink.data.permalink, "id": id, "portalid": portalid, "firmid": firmid, "userid": userid });
        console.log("history saving", postHistoryResponse)

        if (permalink.data.permalink) {
          postcount = postcount + 1;
        }

        fetchhistory();

      } catch (error) {
        console.error("Error posting to Instagram:", error);
      }
    }

    if (processedIDs.length === 0) {
      alert("Please select a page before posting")
    }
    else
      if (processedIDs.length === postcount) {
        alert("Content posted to all accounts")
      }
      else if (postcount === 0) {
        alert("There was an issue, content was not posted in any accounts,any permissions were not granted ?")

      }
      else if (processedIDs.length > postcount) {
        alert("Content posted to some accounts only")
      }
    setLoading(false);


  }

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    return `${day}/${month}/${year}`;
  }


  const addmorepages = (event) => {
    event.preventDefault();

    //dev
    //window.location.href = `https://www.facebook.com/v19.0/dialog/oauth?client_id=601794092158200&redirect_uri=http%3A%2F%2Flocalhost%3A7100%2Fapprovestory&state=12345&scope=pages_show_list,instagram_basic,instagram_content_publish`


    //prod
    window.location.href = `https://www.facebook.com/v19.0/dialog/oauth?client_id=270784999165198&redirect_uri=https://myblocks.in/approvestory&state=12345&scope=pages_show_list,instagram_basic,instagram_content_publish`


  }

  return (
    <div>

      <button className='button-blue' onClick={posttoinsta}>Post to Instagram</button>&nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;
      <img src="../images/facebook/Instagram_icon.png" />&nbsp;
      <a href="#" onClick={addmorepages}>

        Link/Unlink Instagram Pages
      </a> Select the Facebook pages that have the instagram account linked to them & the instagram account that you need to add here

      <br /> <br />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <a href='/imageupload'>Go Back</a>
      </div>
      <br />

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {loading && <img src="../images/website_images/Spinner-1s-200px.gif" alt="Loading..." style={{ width: '100px', height: '100px' }} />}
      </div>
      {/* <table className="content-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Posted Url</th>


          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.ID}>

              <td>{item.ID}</td>
              {console.log(item)}
              <td>
                <a href={item.URL} >{item.URL}</a>

              </td>

            </tr>
          ))}
        </tbody>
      </table> */}
      <div className='previous-post-header'>Previously Posted on...</div>
      <div className='facebook-like-count'>
        {data.map((item) => (
          <div className='post-row'>
            <div>{formatDate(item.DATE)}</div>
            <a href={item.URL} > Go to Post</a>
            <div className='metrics-display'><img className='icon-reload' src={likesIcon} />
              {item.likes === undefined ? (
                <div className='count-display'> 0</div>
              ) : (<div className='count-display'>{item.likes}</div>)}
              {/* <img className='icon-reload' src={reloadIcon} onClick={() => getlikes(item.ID)} /> */}
            </div>
            <div className='metrics-display'><img className='icon-reload' src={commentIcon} />
              {item.COMMENT === null ? (
                <div className='count-display'> 0</div>
              ) : (<div className='count-display'>{item.COMMENT}{console.log(item.COMMENT)}</div>)}
              {/* <img className='icon-reload' src={reloadIcon} onClick={() => getcomments(item.ID)} /> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default InstaPosting